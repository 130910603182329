<script setup>
import ArrowDown from '~/assets/icons/arrow-down.svg'
import Check from '~/assets/icons/check.svg'

defineProps({
    choices: {
        type: Array,
        required: true,
        default: () => [],
    },
    selectClasses: {
        type: String,
        default: '',
    },
})
const selectValue = defineModel({ type: [String, Number, Boolean] })
</script>

<template>
    <div class="relative max-w-full inline-block">
        <select
            v-model="selectValue"
            :class="selectClasses"
            class="appearance-none max-w-full rounded-4xl border-2 border-lightblue bg-transparent py-2 pl-12 pr-12 uppercase lg:py-3 lg:pl-12"
        >
            <option
                v-for="choice in choices"
                :key="choice.key"
                :value="choice.value"
            >
                {{ choice.label }}
            </option>
        </select>
        <span
            class="pointer-events-none absolute left-2 top-1/2 mr-7 flex size-7 -translate-y-1/2 items-center justify-center rounded-full border border-white lg:left-3 lg:size-6 lg:border-2"
        >
            <Check class="h-auto w-3 fill-white" />
        </span>
        <ArrowDown
            class="pointer-events-none absolute right-3.5 top-1/2 w-2.5 -translate-y-1/2 fill-white lg:right-5 lg:w-5"
        />
    </div>
</template>
