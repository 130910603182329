<script setup>
import Xbutton from '~/assets/icons/X.svg'

const {
    sites,
    activeSite,
    countryBannerVisible,
    switchActiveSite,
    translations,
} = useSharedState()

const selectedSite = ref(activeSite.value.metalang);

const selectValues = filterAndSortSites(sites.value, activeSite.value);

const switchSite = async () => {
    await switchActiveSite(selectedSite.value, true)
}
</script>

<template>
    <Transition
        leave-active-class="transition duration-650"
        leave-from-class="translate-y-0"
        leave-to-class="-translate-y-32 xl:-translate-y-24"
    >
        <div v-show="countryBannerVisible" class="country-selector fixed top-0 z-70 flex h-32 w-full items-center overflow-hidden bg-darkblue py-5 pl-5 pr-4 text-white will-change-[margin-top] xl:h-24 xl:px-10">
            <div class="w-36vw sm:w-40vw text-sm lg:mr-12 lg:w-auto xl:mb-0 xl:mr-32">
                {{ translations?.countrySelectorPrompt }}
            </div>
            <div class="select-wrapper flex w-full items-center text-sm lg:w-auto">
                <div class="w-[90%] lg:flex lg:w-auto">
                    <CustomSelect v-model="selectedSite" :choices="selectValues" />
                    <button
                        class="mt-2.5 w-full rounded-4xl bg-gold px-4 py-2 text-left font-medium uppercase text-midblue lg:ml-9 lg:mt-0 lg:w-auto lg:px-6 lg:py-3 lg:text-center"
                        v-on:click.prevent="switchSite"
                    >
                        {{ translations?.countrySelectorContinue }}
                    </button>
                </div>

                <button class="ml-4 mt-1.5 size-4 self-start lg:ml-8 lg:mt-0 lg:self-center">
                    <Xbutton class="h-auto w-full fill-white" v-on:click.prevent="switchSite" />
                </button>
            </div>
        </div>
    </Transition>
</template>
