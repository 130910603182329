<script setup>
const month = ref('')
const day = ref('')
const year = ref('')
const error = ref('')

const {
    scrolledMedium,
} = useClientState()

const {
    sites,
    activeSite,
    switchActiveSite,
    olnuf,
    setOlnuf,
    globals,
    translations,
} = useSharedState()

const selectedSite = ref(activeSite.value.metalang)

const selectValues = filterAndSortSites(sites.value, activeSite.value);

const switchSite = async () => {
    const selectedDate = new Date(parseInt(year.value, 10), parseInt(month.value, 10) - 1, parseInt(day.value, 10))
    if (year.value !== '' && month.value !== '' && day.value !== '' && selectedDate instanceof Date && !Number.isNaN(selectedDate)) {
        /* Get the age of that date */
        const age = Math.floor((new Date() - selectedDate) / 1000 / 60 / 60 / 24 / 365)

        const comparedAge = globals?.value?.ageGate?.age ? parseInt(globals.value.ageGate.age, 10) : 18;
        if (age >= comparedAge) {
            error.value = ''
            setOlnuf(true)
            await switchActiveSite(selectedSite.value)
        }
        else {
            error.value = globals?.value?.ageGate?.ageErrorMessage.replace('{age}', comparedAge)
        }
    }
    else {
        error.value = globals?.value?.ageGate?.invalidDateErrorMessage
    }
}

const redirectToSafety = () => {
    navigateTo('https://www.google.com', { external: true })
}
</script>

<template>
    <Transition leave-active-class="transition duration-650" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <form v-show="globals?.ageGate?.image?.[0]?.url && olnuf === false" data-lenis-prevent
            class="size-screen fixed left-0 top-0 z-70 items-center justify-center overflow-auto bg-darkblue text-white"
            v-on:submit.prevent="switchSite">
            <img class="fixed z-0 size-full object-cover object-top" :src="globals?.ageGate?.image?.[0]?.url"
                :srcset="globals?.ageGate?.image?.[0]?.srcset" :alt="globals?.ageGate?.image?.[0]?.alt"
                :width="globals?.ageGate?.image?.[0]?.width" :height="globals?.ageGate?.image?.[0]?.height">
            <div class="relative z-10">
                <div class="relative z-10">
                    <Transition enter-active-class="transition delay-500" enter-from-class="opacity-0 translate-y-2"
                        enter-to-class="opacity-100 translate-y-0" leave-active-class="absolute transition"
                        leave-from-class="top-0 left-0 opacity-100" leave-to-class="top-0 left-0 -translate-y-2 opacity-0">
                        <LogoEn v-show="activeSite.shortlang === 'en'"
                            class="mx-4 md:mx-8 mt-8 h-auto w-full max-w-[calc(100vw-2rem)] fill-current md:w-78 lg:mt-16"
                            :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }" />
                    </Transition>

                    <Transition enter-active-class="transition delay-500" enter-from-class="opacity-0 translate-y-2"
                        enter-to-class="opacity-100 translate-y-0" leave-active-class="absolute transition"
                        leave-from-class="top-0 left-0 opacity-100" leave-to-class="top-0 left-0 -translate-y-2 opacity-0">
                        <LogoFr v-show="activeSite.shortlang === 'fr'"
                            class="mx-4 md:mx-8 mt-8 h-auto w-full max-w-[calc(100vw-2rem)] fill-current md:w-78 lg:mt-16"
                            :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }" />
                    </Transition>
                </div>
                <div
                    class="mt-8 w-full xs:mx-4 xs:w-[calc(100vw-2rem)] max-w-xl md:mx-auto rounded-2xl bg-midblue/60 px-4 md:px-8 py-12 text-center text-base">
                    <div v-if="globals?.ageGate?.toptitle" class="mb-3 text-xxs xs:text-xs uppercase">
                        {{ globals.ageGate.toptitle }}
                    </div>
                    <div class="mb-4">
                        <div v-if="selectValues.length" class="relative m-auto w-full lg:w-92">
                            <CustomSelect v-model="selectedSite"
                                select-classes="!bg-faderblue text-xxs xs:text-sm md:text-base !border-faderblue"
                                :choices="selectValues" />
                        </div>
                    </div>
                    <div v-if="globals?.ageGate?.maintitle" class="mb-1 mt-4 text-xl xs:text-3xl leading-none">
                        {{ globals.ageGate.maintitle }}
                    </div>
                    <div v-if="globals?.ageGate?.subtitle" class="mb-4 text-xxs xs:text-xs uppercase">
                        {{ globals.ageGate.subtitle }}
                    </div>
                    <div class="flex gap-3 lg:grid-cols-3">
                        <input v-model="month"
                            class="mb-4 w-full border-b-2 text-xxs xs:text-xs md:text-base border-gold bg-transparent py-2 appearance-none rounded-none placeholder:text-white focus-visible:border-white focus-visible:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                            type="number" min="1" max="12" pattern="[0-9]{1,2}"
                            :placeholder="translations?.monthPlaceholder ?? 'Month (MM)'" required>
                        <input v-model="day"
                            class="mb-4 w-full border-b-2 text-xxs xs:text-xs md:text-base border-gold bg-transparent py-2 appearance-none rounded-none placeholder:text-white focus-visible:border-white focus-visible:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                            type="number" min="1" max="31" pattern="[0-9]{1,2}"
                            :placeholder="translations?.dayPlaceholder ?? 'Day (DD)'" required>
                        <input v-model="year"
                            class="mb-4 w-full border-b-2 text-xxs xs:text-xs md:text-base border-gold bg-transparent py-2 appearance-none rounded-none placeholder:text-white focus-visible:border-white focus-visible:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                            type="number" min="1900" pattern="[0-9]{4}"
                            :placeholder="translations?.yearPlaceholder ?? 'Year (YYYY)'" required>
                    </div>

                    <div v-if="error" class="mt-2 text-center text-sm text-red-500">
                        {{ error }}
                    </div>
                    <div class="mt-4 flex justify-center gap-4">
                        <button
                            class="order-2 mt-2.5 w-full rounded-4xl bg-gold px-4 py-2 font-medium uppercase text-white lg:ml-3 lg:mt-0 lg:w-56 lg:px-6 lg:py-3 text-center"
                            type="submit">
                            {{ globals?.ageGate?.enter ?? 'Enter' }}
                        </button>
                        <button
                            class="order-1 mt-2.5 w-full rounded-4xl bg-faderblue px-4 py-2 font-medium uppercase text-white lg:mt-0 lg:w-56 lg:px-6 lg:py-3 text-center"
                            v-on:click.prevent="redirectToSafety">
                            {{ globals?.ageGate?.leave ?? 'Leave' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </Transition>
</template>
