<script setup>
// import Logo from '~/assets/icons/Geloso_Logo_EN.svg';
import Hamburger from '~/assets/icons/Hamburger.svg'
import ArrowDown from '~/assets/icons/arrow-down.svg'

const lenis = useLenis()
const {
    navOpened,
    scrolledSmall,
    footerVisible,
    firstPanelIsBlue,
    submenuOpened,
    scrolledMedium,
} = useClientState()

const { navs, activeSite, countryBannerVisible } = useSharedState()

const itemsLength = ref(0)
const textColor = ref('text-white')

onNuxtReady(() => {
    watch(submenuOpened, () => {
        if (submenuOpened.value) {
            lenis.value.stop()
            textColor.value = 'text-white xl:text-midblue'
        }
        else {
            lenis.value.start()
            textColor.value = 'text-white xl:text-white'
        }
    })
})

itemsLength.value = (navs?.value?.main?.length ?? 0) + (navs?.value?.secondary?.length ?? 0)

const openCloseMenu = (event, id, url) => {
    // Prevent default to open the menu only if the url is non-existant like passive nav nodes
    if (!url) {
        submenuOpened.value = submenuOpened.value === id ? null : id;
        event.preventDefault();
    }
}
</script>

<template>
    <div v-if="navs" class="header backface-hidden fixed left-0 top-0 z-52 w-full transition-all duration-650"
        :class="{ '-translate-y-4 xl:-translate-y-10': scrolledSmall, '!-translate-y-full': footerVisible, 'bg-midblue': !firstPanelIsBlue, 'top-32 xl:top-24': countryBannerVisible }">
        <PartialsNavDesktopSecondary :data="navs?.secondary" />

        <!-- Desktop main nav -->
        <div class="nav-main relative z-11 flex h-28 items-center justify-between pb-6 pl-7 pr-6 pt-9 transition-all duration-650 lg:h-auto lg:py-9 lg:pl-13 lg:pr-20 xl:items-end"
            :class="[textColor, {
                'bg-lightblue': !firstPanelIsBlue,
                'bg-lightblue xl:rounded-t-none xl:py-3': scrolledMedium,
                'xl:rounded-t-4xl xl:py-9': !scrolledMedium,
            }]">
            <PartialsNavDesktopMegaMenu v-for="item in navs.main" :key="item.id" :item="item"
                :display="submenuOpened === item.id" />
            <MainLink :to="`/${activeSite.prefix}`" class="relative transition-all duration-500 will-change-transform shrink-0 grow-0 z-10 block"
                :class="{ 'w-48': scrolledMedium, 'w-[12rem] lg:w-[17.33333333rem]': !scrolledMedium }">
                <Transition enter-active-class="transition delay-500" enter-from-class="opacity-0 translate-y-2"
                    enter-to-class="opacity-100 translate-y-0" leave-active-class="absolute transition"
                    leave-from-class="top-0 left-0 opacity-100" leave-to-class="top-0 left-0 -translate-y-2 opacity-0">
                    <LogoEn v-show="activeSite.shortlang === 'en'" class="h-auto w-full fill-current"
                        :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }" />
                </Transition>

                <Transition enter-active-class="transition delay-500" enter-from-class="opacity-0 translate-y-2"
                    enter-to-class="opacity-100 translate-y-0" leave-active-class="absolute transition"
                    leave-from-class="top-0 left-0 opacity-100" leave-to-class="top-0 left-0 -translate-y-2 opacity-0">
                    <LogoFr v-show="activeSite.shortlang === 'fr'" class="h-auto w-full fill-current"
                        :wordmark-classes="{ 'opacity-0': scrolledMedium, 'opacity-100': !scrolledMedium }" />
                </Transition>
            </MainLink>
            <div class="relative z-10 hidden xl:block">
                <ul class="flex items-center justify-end gap-x-6 text-base uppercase">
                    <li v-for="item in navs.main" :key="item.id">
                        <MainLink active-class="text-gold" :to="item.url"
                            class="flex cursor-pointer items-center transition hover:text-gold"
                            :class="{ 'text-gold': submenuOpened === item.id }"
                            v-on:click.prevent="openCloseMenu($event, item.id, item.url)">
                            {{ item.title }}

                            <ArrowDown v-if="item?.children?.length"
                                class="ml-1.5 h-auto w-3 fill-current transition duration-650"
                                :class="{ '-rotate-180': submenuOpened === item.id }"
                                v-on:click.prevent.stop="submenuOpened = submenuOpened === item.id ? null : item.id" />
                        </MainLink>
                    </li>
                </ul>
            </div>
            <button class="relative z-10 ml-4 shrink-0 grow-0 xl:hidden" v-on:click.prevent="navOpened = !navOpened">
                <Hamburger class="w-7 xs:w-10 fill-white" />
            </button>
        </div>

        <!-- Fake rounded corners and borders -->
        <div aria-hidden
            class="nav-left-corner pointer-events-none absolute bottom-[calc(-7.5rem-1px)] left-0 z-10 h-30 w-8 rounded-tl-4xl shadow-inverted-radius-top-left transition-[box-shadow,border-radius] duration-650"
            :class="{
                'rounded-tl-none shadow-none': (footerVisible || !scrolledMedium) && firstPanelIsBlue,
            }" />
        <div aria-hidden
            class="nav-right-corner pointer-events-none absolute bottom-[calc(-7.5rem-1px)] right-0 z-10 h-30 w-8 rounded-tr-4xl shadow-inverted-radius-top-right transition-[box-shadow,border-radius] duration-650"
            :class="{
                'rounded-tr-none shadow-none': (footerVisible || !scrolledMedium) && firstPanelIsBlue,
            }" />
        <div aria-hidden
            class="absolute inset-x-[calc(2rem-1px)] -bottom-px z-9 h-px bg-fadedblue opacity-0 transition duration-650"
            :class="{ 'opacity-100': !submenuOpened && scrolledMedium && !footerVisible || !firstPanelIsBlue }" />
    </div>
</template>
