<script setup>
const { scrolledSmall } = useClientState()

defineProps({
    item: {
        type: Object,
        required: true,
        default: () => ({ children: [] }),
    },
    display: {
        type: Boolean,
        default: false,
    },
})

const containsSubchildren = (item) => {
    let hasSubChildren = false
    for (let i = 0, il = item.children.length; i < il; ++i) {
        const child = item.children[i]
        if (child?.children?.length) {
            hasSubChildren = true
        }
    }
    return hasSubChildren
}
</script>

<template>
    <Transition
        enter-active-class="overflow-hidden transition duration-650"
        enter-from-class="translate-y-[100vh]"
        enter-to-class="translate-y-0"
        leave-active-class="overflow-hidden transition duration-650"
        leave-from-class="translate-y-0"
        leave-to-class="translate-y-[100vh]"
    >
        <div
            v-show="display"
            v-if="item?.children?.length"
            data-lenis-prevent
            class="absolute left-0 top-0 z-10 hidden w-full overflow-auto rounded-t-4xl bg-white xl:block"
            :class="{
                'max-h-[calc(100vh-2.5rem)]': !scrolledSmall,
                '!max-h-screen': scrolledSmall,
            }"
        >
            <div class="px-28 pb-8 pt-28">
                <div class="nav-title xl:text-6xl 2xl:text-9xl">
                    <Transition
                        :css="false"
                        v-on:enter="transitionListEnter"
                        v-on:leave="transitionListLeaveEmpty"
                    >
                        <div v-show="display" :data-index="1">
                            <div class="h-[1.6cap]" v-html="item?.excerpt ? item.excerpt : item?.title" />
                        </div>
                    </Transition>
                    <Transition
                        :css="false"
                        v-on:enter="transitionListEnter"
                        v-on:leave="transitionListLeaveEmpty"
                    >
                        <div
                            v-show="display"
                            :data-index="1"
                            class="mb-12 mt-4 h-[2px] w-24 bg-current"
                        />
                    </Transition>
                </div>
                <ul :class="{ 'grid grid-cols-4 gap-4': !containsSubchildren(item), 'flex h-76 3xl:h-100 flex-col flex-wrap gap-3': containsSubchildren(item) }">
                    <li
                        v-for="(child, childIndex) in item.children"
                        :key="child.id"
                        :class="{ 'w-fit': !child?.children?.length, '-mt-8 ml-8 w-3/4': child?.children?.length }"
                    >
                        <Transition
                            :css="false"
                            v-on:enter="transitionListEnter"
                            v-on:leave="transitionListLeaveEmpty"
                        >
                            <div
                                v-if="child?.children?.length"
                                v-show="display"
                                class="mb-4 text-xl font-medium leading-none lg:text-2xl lg:leading-none"
                                :data-index="childIndex"
                                v-html="child.title"
                            />
                            <Button
                                v-else
                                v-show="display"
                                size="small"
                                :data-index="childIndex"
                                :to="child.url"
                                circle="bg-gold"
                                arrow="fill-white"
                                text="text-current"
                                active-class="text-gold"
                                :data-parent-menu="item.id"
                            >
                                {{ child.title }}
                            </Button>
                        </Transition>

                        <div v-if="child?.children?.length">
                            <TransitionGroup
                                tag="ul"
                                :css="false"
                                class="grid grid-cols-3 w-full gap-4"
                                v-on:enter="transitionListEnter"
                                v-on:leave="transitionListLeaveEmpty"
                            >
                                <li
                                    v-for="(subChild, subChildIndex) in child.children"
                                    v-show="display"
                                    :key="subChild.id"
                                    class="w-full"
                                    :data-index="subChildIndex+item.children.length"
                                >
                                    <ProductCard
                                        class="!transition-none"
                                        :product="{ url: subChild.url, title: subChild.title, image: subChild.element.image }"
                                    />
                                </li>
                            </TransitionGroup>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="item?.ctas?.length" class="flex w-full">
                <Button
                    v-for="(cta, ctaIndex) in item?.ctas"
                    :key="'link'+ctaIndex"
                    :to="cta.url"
                    class="relative z-10 w-full justify-center bg-gold py-2"
                    text="text-white"
                    arrow="fill-gold"
                    circle="bg-white"
                >
                    <span v-html="cta.title" />
                </Button>
            </div>
        </div>
    </Transition>
</template>
